if (module.hot) {
  module.hot.accept();
}

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

window.onload = function() {
  setTimeout(function(){$('main.holder').removeClass('prevent-transition');}, 100);
}

if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
  $(document).ready(function(){

      $('body').addClass('mobile');

      $('a.map-link').click(function(e){
        $('a.map-link').removeClass('selected');
        e.currentTarget.classList.add('selected');
      })

  })
}
